/* eslint-disable max-len */
import { SVGProps } from 'react';

export const FlipAsset = (props: SVGProps<SVGSVGElement>): JSX.Element => (
    <svg fill="none" height="20" viewBox="0 0 20 20" width="20" xmlns="http://www.w3.org/2000/svg" {...props}>
        <path
            d="M13 3H17C18.1046 3 19 3.89543 19 5V5.5V8M13 3L15 1M13 3L15 5M10.2 19H5.8C4.1198 19 3.2798 19 2.638 18.673C2.0735 18.3854 1.6146 17.9265 1.327 17.362C1 16.7202 1 15.8802 1 14.2V13.8C1 12.1198 1 11.2798 1.327 10.638C1.6146 10.0735 2.0735 9.6146 2.638 9.32698C3.2798 9 4.1198 9 5.8 9H10.2C11.8802 9 12.7202 9 13.362 9.32698C13.9265 9.6146 14.3854 10.0735 14.673 10.638C15 11.2798 15 12.1198 15 13.8V14.2C15 15.8802 15 16.7202 14.673 17.362C14.3854 17.9265 13.9265 18.3854 13.362 18.673C12.7202 19 11.8802 19 10.2 19Z"
            stroke="currentColor"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
        />
    </svg>
);

FlipAsset.displayName = 'FlipAsset';
